"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _rawMaterial = require("@/api/rawMaterial");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        requester: 'jpbs',
        action: 'storage'
      },
      total: 0
    };
  },
  computed: {
    dateFilter: {},
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user'])
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _rawMaterial.getPackLabRawMaterials)(this.listQuery).then(response => {
        this.list = response;
      }).finally(() => {
        this.listLoading = false;
      });
    }
  }
};
exports.default = _default;