var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.rawMaterial,
            rules: _vm.rules,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.rawMaterial.uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "uuid", $$v)
                  },
                  expression: "rawMaterial.uuid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "SKU", prop: "sku" } },
            [
              _c("el-autocomplete", {
                attrs: {
                  "popper-class": "wide-dropdown",
                  "fetch-suggestions": _vm.querySearch,
                },
                on: { select: _vm.handleSelect },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.sku)),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.rawMaterial.sku,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "sku", $$v)
                  },
                  expression: "rawMaterial.sku",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("Order.Name"), prop: "nameEng" } },
            [
              _c("el-autocomplete", {
                attrs: {
                  "popper-class": "wide-dropdown",
                  "fetch-suggestions": _vm.querySearch,
                },
                on: { select: _vm.handleSelect },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.nameEng)),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.rawMaterial.nameEng,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "nameEng", $$v)
                  },
                  expression: "rawMaterial.nameEng",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Unit", prop: "unit" } },
            [
              _c("el-input", {
                attrs: { maxlength: "8" },
                model: {
                  value: _vm.rawMaterial.unit,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "unit", $$v)
                  },
                  expression: "rawMaterial.unit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("route.Supplier") } },
            [
              _c("el-autocomplete", {
                staticClass: "inline-input",
                attrs: {
                  "fetch-suggestions": _vm.fetchSupplier,
                  placeholder: "请输入内容",
                  "value-key": "label",
                  clearable: "",
                },
                on: {
                  clear: _vm.clearSupplier,
                  select: _vm.handleSelectSupplier,
                  blur: _vm.checkSupplier,
                },
                model: {
                  value: _vm.supplierName,
                  callback: function ($$v) {
                    _vm.supplierName = $$v
                  },
                  expression: "supplierName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Unit Price" } },
            [
              _c("el-input-number", {
                attrs: { precision: 2, step: 0.1, min: 0 },
                model: {
                  value: _vm.rawMaterial.defaultPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "defaultPrice", $$v)
                  },
                  expression: "rawMaterial.defaultPrice",
                },
              }),
              _vm._v(" "),
              _vm.$route.params.id
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogPriceFormVisible = true
                        },
                      },
                    },
                    [_vm._v("Historical")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Packing" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.rawMaterial.packing,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "packing", $$v)
                  },
                  expression: "rawMaterial.packing",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Sub Unit" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.rawMaterial.subUnit,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "subUnit", $$v)
                  },
                  expression: "rawMaterial.subUnit",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Sub Unit per Qty" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.rawMaterial.subUnitQty,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "subUnitQty", $$v)
                  },
                  expression: "rawMaterial.subUnitQty",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "存倉期限 (天)" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.rawMaterial.storagePeriod,
                  callback: function ($$v) {
                    _vm.$set(_vm.rawMaterial, "storagePeriod", $$v)
                  },
                  expression: "rawMaterial.storagePeriod",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseRequests.Store"),
                prop: "stores",
              },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.allStoreChecked,
                    callback: function ($$v) {
                      _vm.allStoreChecked = $$v
                    },
                    expression: "allStoreChecked",
                  },
                },
                [_vm._v("All")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.rawMaterial.stores,
                    callback: function ($$v) {
                      _vm.$set(_vm.rawMaterial, "stores", $$v)
                    },
                    expression: "rawMaterial.stores",
                  },
                },
                _vm._l(_vm.stores, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Warehouse", prop: "warehouses" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.allWarehouseChecked,
                    callback: function ($$v) {
                      _vm.allWarehouseChecked = $$v
                    },
                    expression: "allWarehouseChecked",
                  },
                },
                [_vm._v("All")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.rawMaterial.warehouses,
                    callback: function ($$v) {
                      _vm.$set(_vm.rawMaterial, "warehouses", $$v)
                    },
                    expression: "rawMaterial.warehouses",
                  },
                },
                _vm._l(_vm.warehouses, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Category", prop: "categories" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.rawMaterial.categories,
                    callback: function ($$v) {
                      _vm.$set(_vm.rawMaterial, "categories", $$v)
                    },
                    expression: "rawMaterial.categories",
                  },
                },
                _vm._l(_vm.categories, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isOffline },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/raw-materials" },
                },
                [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.params.id && _vm.keepAliveDialogPriceFormVisible
        ? [
            _c("price-dialog", {
              attrs: {
                "raw-material-id": _vm.$route.params.id,
                "dialog-form-visible": _vm.dialogPriceFormVisible,
              },
              on: {
                "update:dialogFormVisible": function ($event) {
                  _vm.dialogPriceFormVisible = $event
                },
                "update:dialog-form-visible": function ($event) {
                  _vm.dialogPriceFormVisible = $event
                },
                complete: _vm.onPriceChange,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }