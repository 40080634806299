var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("br"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            border: "",
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "Raw Material",
              width: "400",
              prop: "nameEng",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.sku ? scope.row.sku : null) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "存倉期限",
              width: "150",
              prop: "storage_period",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.storage_period
                            ? scope.row.storage_period
                            : null
                        ) +
                        "日\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "最後存倉日期",
              width: "180",
              prop: "last_storage_date",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.last_storage_date) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "已存倉日數", prop: "stored_day", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.stored_day ? scope.row.stored_day : null
                        ) +
                        "日\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "超出存倉日數", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.stored_day > scope.row.storage_period
                            ? scope.row.stored_day - scope.row.storage_period
                            : 0
                        ) +
                        "日\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }